.home-container{
    height: 300px;
    color: white;
    padding-left: 150px;

}
.home-content h2 {
    padding: 5px;
    padding-top: 70px;
}
.home-content h1 {
    color: #e0b50f;
    padding: 5px;
}
.home-buttons{
 margin-top: 20px;
}
.home-buttons .btn-hire{
    background-color: #3b8ff3;
    color: white;
    width: 160px;
}
.home-buttons .btn-hire:hover{
    background-color: white;
    color: #3b8ff3;
    width: 200px;
    transition: 1s;
}
.home-buttons .btn-cv{
    background-color: #f29f67;
    color: white;
    margin-left: 15px;
}
.home-buttons .btn-cv:hover{
    opacity: 80%;
    transition: 1s;
}
.theme-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    background-color: #1e1e1e;
    text-align: center;
    position: fixed;
    right: 0;
    cursor: pointer;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
}

/*===========*/
@media (max-width:600px) {
    .home-container{
        padding: 0;
        margin: 0;
        margin-top: 50px;
    }
    .home-container h2{
        font-size: 24px;
    }
    .home-container h1{
        font-size:24px ;
    }
}