
.int{
 background-color: #1387812a;
 margin-left: 160px;
}
.work-int{
    padding: 60px 0px;
}
.work-int h2{
    color: #095754;
    font-weight: bold;
}
.work-int hr{
    color: #138781;
    width: 400px;
    margin: 10px auto;
}
.work-int .vertical-timeline-element-title{
    color: #138781;
}
.work-int .vertical-timeline-element-subtitle{
    color: rgb(90, 87, 87);
}

/* ===== DARK MODE ========== */
#dark .int {
    background-color: #643a6b;
  }
  
  #dark .work-int h2 {
    color: white;
  }

/*===========================*/
@media (max-width:600px)  {
    .int{
        margin: 0;
        padding: 0;
        margin-top: 50px;
    }
    .int hr{
        width: 280px;
    }
}
  