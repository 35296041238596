.techstack{
    padding-left: 140px;
    margin-top: 40px ;
}
.techstack hr{
    color: #138781;
    width: 400px;
    margin: 10px auto;
}
.techstack h2{
 font-weight: bold;
 color: #138781;
}
.techstack .card{
    cursor: pointer;
}
.tech-icon{
    font-size: 2rem;
    margin-right: 10px;
}
.techstack .card:hover{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #138781;
    color: white;
    transition: 1s;
}

/*==================*/
@media (max-width:600px){
    .techstack{
    padding: 0;
    margin: 0;
    margin-top: 50;
    }
    .techstack hr{
        width: 280px;
    }
}